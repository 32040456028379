import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  OutlinedInput,
  Tooltip,
  FormControlLabel,
  ListSubheader,
  Box,
  Chip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth } from '../../../../hooks/useAuth';
import { useUploadResources } from '../resources/hooks/useUploadResources'; 
import { sendCohortEmail_Gql } from '../../../../gql/cohort/sendCohortEmail';
import { EmailRecipientType } from './CohortEmail';

import {
  GetRegistrantsForCohort_getRegistrantsForCohort,
  SelectedUserInput,
  SendCohortEmail,
  SendCohortEmailVariables,
  GetAttendanceForSession_getAttendanceForSession,
  GetAttendanceForSessionVariables,
  GetAttendanceForSession,
  GetResourceByProgram
} from '../../../../models/GeneratedModels';

import YesNoDialog from '../../../global/YesNoDialog';
import ProgressIndicator from '../../../global/ProgressIndicator';

import { getRegistrantsForCohortQuery_Gql } from 'gql/cohortRegistration/getRegistrantsForCohortQuery';
import { getAttendanceForSession_Gql } from 'gql/cohortAttendance/getAttendanceForSession';
import { getResourceForProgram_Gql } from 'gql/programResources/getResourceForProgram';

import 'react-quill/dist/quill.snow.css';

const R = require('ramda');
const ReactQuill = require('react-quill');

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      height: '300px',
    },
    accountSelect: {
      width: '100%',
      marginBottom: 2,
      textAlign: 'left',
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 300,
    },
  },
};

interface SendEmailDialogProps {
  isOpen: boolean;
  onClose: () => void;
  recipientType: EmailRecipientType;
  cohortId: string;
  sessionId?: string;
  sessionOption?: string;
  sessionName?: string;
  programID: string
}

const SendEmailDialog = (props: SendEmailDialogProps) => {
  const {
    isOpen,
    onClose,
    recipientType,
    cohortId,
    sessionId,
    sessionOption,
    sessionName,
    programID
  } = props;
  const auth = useAuth();
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [allowInactiveUsers, setAllowInactiveUsers] = useState(false);
  const [validForm, setValidForm] = useState(true);
  const [emailSentConfirmation, setEmailSentConfirmation] = useState(false);
  const [emailCount, setEmailCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [newRegistrants, setNewRegistrants] = useState<
    GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [approvedRegistrants, setApprovedRegistrants] = useState<
    GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [deniedRegistrants, setDeniedRegistrants] = useState<
    GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [waitlistedRegistrants, setWaitlistedRegistrants] = useState<
    GetRegistrantsForCohort_getRegistrantsForCohort[]
  >([]);
  const [selectedRegistrants, setSelectedRegistrants] = useState<string[]>([]);

  const [sessionAllAttendees, setSessionAllAttendees] = useState<GetAttendanceForSession_getAttendanceForSession[]>([]);
  const [sessionPresentAttendees, setSessionPresentAttendees] = useState<GetAttendanceForSession_getAttendanceForSession[]>([]);
  const [sessionAbsentAttendees, setSessionAbsentAttendees] = useState<GetAttendanceForSession_getAttendanceForSession[]>([]);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState<{ file: string; error: string }[]>([]);

  const { addResourcesToS3AndDB } = useUploadResources();

  const [sendEmail, { loading: sendCohortEmailLoading }] = useMutation<
    SendCohortEmail,
    SendCohortEmailVariables
  >(sendCohortEmail_Gql);

  const { loading: resourcesLoading, data: resourceData } = useQuery<GetResourceByProgram>(getResourceForProgram_Gql, {
    variables: {
      programId: programID,
    },
  });

  const resources = useMemo(() => {
    return resourceData?.getResourceByProgram?.filter((res) => res.resourceType?.name !== 'URL')
  }, [resourceData])

  const { loading: isLoading, data: registrantsData } = useQuery(
    getRegistrantsForCohortQuery_Gql,
    {
      variables: {
        cohortId,
      },
    }
  );

  const { loading: attendanceLoading, data: attendanceData } = useQuery<
    GetAttendanceForSession,
    GetAttendanceForSessionVariables
  >(getAttendanceForSession_Gql, {
    variables: {
      cohortId,
      sessionId: `${sessionId}`,
    },
    skip: !sessionId || sessionId.length === 0,
  });

  useEffect(() => {
    if (isLoading || !registrantsData) return;
    const allRegistrants = registrantsData.getRegistrantsForCohort;

    if (allRegistrants) {
      // New registrants
      const isNewActive = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        !x.isApproved && !x.isWaitlisted && !x.isDenied && x.isActive;
      const isNewAll = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        !x.isApproved && !x.isWaitlisted && !x.isDenied;
      
      allowInactiveUsers ? setNewRegistrants(R.filter(isNewAll, allRegistrants)): setNewRegistrants(R.filter(isNewActive, allRegistrants));

      // Approved registrants
      const isApprovedActive= (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        x.isApproved && x.isActive;
      const isApprovedAll = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        x.isApproved;
      allowInactiveUsers ? setApprovedRegistrants(R.filter(isApprovedAll, allRegistrants)) : setApprovedRegistrants(R.filter(isApprovedActive, allRegistrants));

      // Denied registrants
      const isDeniedActive = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        x.isDenied && x.isActive;
      const isDeniedAll = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        x.isDenied;
      allowInactiveUsers ? setDeniedRegistrants(R.filter(isDeniedAll, allRegistrants)) : setDeniedRegistrants(R.filter(isDeniedActive, allRegistrants));

      // Watlisted registrants
      const isWaitlistedActive = (
        x: GetRegistrantsForCohort_getRegistrantsForCohort
      ) => x.isWaitlisted && x.isActive;
      const isWaitlistedAll = (
        x: GetRegistrantsForCohort_getRegistrantsForCohort
      ) => x.isWaitlisted;
      allowInactiveUsers ? setWaitlistedRegistrants(R.filter(isWaitlistedAll, allRegistrants)) : setWaitlistedRegistrants(R.filter(isWaitlistedActive, allRegistrants));
    }
  }, [isLoading, allowInactiveUsers, registrantsData]);

  useEffect(() => {
    if (attendanceLoading || !attendanceData) return;
    
    const allSessionAttendees = attendanceData.getAttendanceForSession;
    if (allSessionAttendees) {
      setSessionAllAttendees(allSessionAttendees);
    } else {
      setSessionAllAttendees([]);
    }

    const isPresentAttendees = (x: GetAttendanceForSession_getAttendanceForSession) =>
        x.isPresent;
    setSessionPresentAttendees(R.filter(isPresentAttendees, allSessionAttendees));

    const isAbsentAttendees = (x: GetAttendanceForSession_getAttendanceForSession) =>
      !x.isPresent;
    setSessionAbsentAttendees(R.filter(isAbsentAttendees, allSessionAttendees));
  }, [attendanceLoading, attendanceData]);

  const handleClose = () => {
    onClose();
  };

  const getDialogTitle = () => {
    switch (recipientType) {
      case 'new':

        return 'Email All New Registrants';
      case 'registered':
        return 'Email All Registered Learners';
      case 'waitlisted':
        return 'Email All Waitlisted Learners';
      case 'denied':
        return 'Email All Denied Learners';
      case 'session':
        return `Email Participants (${sessionOption}) for ${sessionName}`;
      default:
        return '';
    }
  };

  const getRegistrants = useCallback(() => {
    let registrantArray: GetRegistrantsForCohort_getRegistrantsForCohort[] | GetAttendanceForSession_getAttendanceForSession[];
    switch (recipientType) {
      case 'new':
        registrantArray = newRegistrants;
        break;
      case 'registered':
        registrantArray = approvedRegistrants;
        break;
      case 'waitlisted':
        registrantArray = waitlistedRegistrants;
        break;
      case 'denied':
        registrantArray = deniedRegistrants;
        break;
      case 'session':        
        if (sessionOption === 'All') {
          registrantArray = sessionAllAttendees;
        } else if (sessionOption === 'Present') {
          registrantArray = sessionPresentAttendees;
        } else {
          registrantArray = sessionAbsentAttendees;
        }
        break;
      default:
        registrantArray = [];
    }

    if (registrantArray.length > 0) {
      // Use Ramda's sortBy and compose functions
      const capitalizeFirstLetter = (str: string): string => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      const getFullName = (r: any) => {
        if (recipientType === 'session') {
          return `${capitalizeFirstLetter(r.firstName)} ${capitalizeFirstLetter(r.lastName)}`;
        } else {
          return `${capitalizeFirstLetter(r.user.firstName)} ${capitalizeFirstLetter(r.user.lastName)}`;
        }
      }
      
      const sortedRegistrants = R.sortBy(
        R.compose(
          R.join(' '), // Combines first name and last name
          R.split(' '), // Splits the full name into an array of [firstName, lastName]
          getFullName
        )
      )(registrantArray);

      return sortedRegistrants;
    }

    return registrantArray;

  }, [recipientType, newRegistrants, approvedRegistrants, waitlistedRegistrants, deniedRegistrants, sessionOption, sessionAllAttendees, sessionPresentAttendees, sessionAbsentAttendees]);

  useEffect(() => {
    if (getRegistrants().length > 0) {
      let tempArr: string[] = [];
      getRegistrants().map((r: any) => {
        if (recipientType === 'session') {
          return tempArr.push(`${r.firstName} ${r.lastName}`);
        } else {
          return tempArr.push(`${r.user.firstName} ${r.user.lastName}`);
        }
      });
      setSelectedRegistrants(tempArr);
    } else {
      setSelectedRegistrants([]);
    }
  }, [getRegistrants, recipientType]);

  const handleRegistrantChange = (
    event: SelectChangeEvent<typeof selectedRegistrants>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedRegistrants(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailSubject(e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllowInactiveUsers(e.target.checked);
  };

  const clearForm = () => {
    setValidForm(true);
    setEmailCount(0);
    setEmailSentConfirmation(false);
    setEmailSubject('');
    setEmailBody('');
  };

  const validateForm = (): boolean => {
    let isValid = true;
    setValidForm(true);

    if (
      emailSubject.trim().length === 0 ||
      !emailBody ||
      emailBody === '<div><br></div>' || selectedRegistrants.length === 0
    ) {
      isValid = false;
      setValidForm(false);
    }

    return isValid;
  };

  const handleConfirmation = async (value: boolean) => {
    setOpenConfirmation(false);

    if (!value) {
      return;
    }

    let selectedUsers: SelectedUserInput[] = [];
    for (let index = 0; index < selectedRegistrants.length; index++) {
      getRegistrants()
        .filter((r: any) => {
          if (recipientType === 'session') {
            const fullNameParts = selectedRegistrants[index].trim().split(' ');
            if (r.firstName.trim() === fullNameParts.slice(0, -1).join(' ').trim() &&
                r.lastName.trim() === fullNameParts.slice(-1).join(' ').trim()) {
              const newSessionUser: SelectedUserInput = {
                id: r.userId,
                email: r.email,
                firstName: r.firstName,
                lastName: r.lastName,
              };
              selectedUsers.push(newSessionUser);
              return null;  
            }
          } else {
            const fullNameParts = selectedRegistrants[index].trim().split(' ');
            if (r.user.firstName.trim() === fullNameParts[0].trim() &&
            r.user.lastName.trim() === (fullNameParts.slice(1)).join(' ').trim()) {
              const newLearnerUser: SelectedUserInput = {
                id: r.user.id,
                email: r.user.email,
                firstName: r.user.firstName,
                lastName: r.user.lastName,
              };
              selectedUsers.push(newLearnerUser);
              return null;
            }
          }
        });
    }
    
    setEmailCount(selectedUsers.length);

    await sendEmail({
      variables: {
        cohortEmail: {
          cohortId,
          sendSelectedUsers: selectedUsers,
          emailType: recipientType,
          sessionId: sessionId ?? null,
          sessionOption: sessionOption ?? null,
          subject: emailSubject,
          text: emailBody,
          senderId: auth.user?.id ?? '',
          attachments: selectedResources
        },
      },
    });

    setEmailSentConfirmation(true);
  };

  const onSendClick = () => {
    if (validateForm()) {
      setOpenConfirmation(true);
    }
  };

  const cancelEmail = () => {
    clearForm();
    handleClose();
  };

  const filteredResources = resources?.filter((resource) =>
    resource.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const uploadFiles = async (files: File[]) => {
    try {
      const { success, errors } = await addResourcesToS3AndDB(programID, files);
      if (success.length > 0) {
        const newResourceNames = files.map((file) => file.name);
        setSelectedResources((prevResources) => [
          ...prevResources,
          ...newResourceNames.filter((name) => !prevResources.includes(name)),
        ]);
      }
      if (errors.length > 0) {
        setErrors(errors);
      }
    } catch (error) {
      console.error('Unexpected error during upload:', error);
      setErrors([{ file: 'Unexpected error', error: 'Unexpected error' }]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setUploading(true);
      setErrors([]);
      const filesArray = Array.from(event.target.files);
      await uploadFiles(filesArray);
      setUploading(false);
      event.target.value = '';
    }
  };

  const handleResourceChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const selectedResourceNames = typeof value === 'string' ? value.split(',') : value;
  
    setSelectedResources((prevResources) => [
      ...prevResources,
      ...selectedResourceNames.filter((name) => !prevResources.includes(name)),
    ]);
  };
  
  const removeResource = (resourceToRemove: string) => {
    setSelectedResources((prevResources) =>
      prevResources.filter((resource) => resource !== resourceToRemove)
    );
  };
  
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{ 
        sx: {
          width: '80vw',
          height: '80vh',
          maxWidth: 'none',
          maxHeight: 'none',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{getDialogTitle()}</DialogTitle>
      <DialogContent>
        <ProgressIndicator isOpen={sendCohortEmailLoading || isLoading || attendanceLoading || resourcesLoading} title="Processing..." />

        <Stack spacing={2}>
          {!validForm && (
            <Alert severity="error">
              Email subject, body and address are required
            </Alert>
          )}

          {emailSentConfirmation && (
            <Alert severity="info">
              Email has been sent to {emailCount} recipients
            </Alert>
          )}

            <FormControl sx={{ mb: 1, width: '100%', marginTop: '10px' }}>
              <InputLabel required id="registrants-label" color="warning">
                To
              </InputLabel>

            <Select
              required
              className={classes.accountSelect}
              labelId="registrants-label"
              multiple
              input={<OutlinedInput label="To" />}
              value={selectedRegistrants}
              onChange={handleRegistrantChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              color="warning"
            >
              {(recipientType === 'session') ?
                getRegistrants().map((r: GetAttendanceForSession_getAttendanceForSession) => (
                  <MenuItem
                    key={r.userId}
                    value={
                      `${r.firstName} ${r.lastName}`}
                  >
                    <Checkbox
                      checked={
                        selectedRegistrants.indexOf(
                          `${r.firstName} ${r.lastName}`
                        ) > -1
                      }
                    />
                    <ListItemText
                      primary={`${r.firstName} ${r.lastName}`}
                    />
                  </MenuItem>
                )) : 
                getRegistrants().map((r: GetRegistrantsForCohort_getRegistrantsForCohort) => (
                <MenuItem
                  key={r.id}
                  value={
                    `${r.user.firstName} ${r.user.lastName}`}
                >
                  <Checkbox
                    checked={
                      selectedRegistrants.indexOf(
                        `${r.user.firstName} ${r.user.lastName}`
                      ) > -1
                    }
                  />
                  <ListItemText
                    primary={`${r.user.firstName} ${r.user.lastName}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            onChange={handleSubjectChange}
            value={emailSubject}
            variant="outlined"
            label="Email Subject"
            color="warning"
          />
          
          {(recipientType === 'registered') &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Email will be sent to Active users">
              <InfoIcon
                sx={{ fontSize: '16px', mr: 2, color: 'primary.main' }}
              />
            </Tooltip>
            
            <FormControlLabel
              control={
                <Checkbox
                color="primary"
                checked={allowInactiveUsers}
                onChange={handleCheckboxChange }
              />
              }
              label="check to include Inactive Users"
            />
          </div>
          }
          <ReactQuill
            id="scheduleDescription"
            className={classes.editor}
            value={emailBody}
            onChange={(value: string) => setEmailBody(value)}
          />
        </Stack>

        {/* LIST OF SELECTED RESOURCES */}

        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          {selectedResources.map((resource, index) => (
            <Chip
              key={index}
              label={resource}
              onDelete={() => removeResource(resource)}
              color="primary"
              variant="outlined"
            />
          ))}
        </Box>

        {/* ERRORS FROM FILE UPLOAD */}

        {errors.length > 0 && (
          <Box marginTop={2}>
            <Alert severity="error">
              {errors.map((error, index) => (
                <div key={index}>
                  <strong>{error.file}:</strong> {error.error}
                </div>
              ))}
            </Alert>
          </Box>
        )}

        {/* FILE SELECT OR UPLOAD */}

        <Stack marginTop={4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <FormControl size='small' sx={{ width: '300px', marginRight: 2 }}>
            <InputLabel id="resource-select-label">Select Resource</InputLabel>
            <Select
              labelId="resource-select-label"
              multiple
              value={selectedResources}
              onChange={handleResourceChange}
              input={<OutlinedInput label="Select Resource" />}
              MenuProps={MenuProps}
              color="primary"
              renderValue={(selected) => selected.join(', ')}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  placeholder="Search..."
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </ListSubheader>
              {filteredResources?.map((resource) => (
                <MenuItem key={resource.id} value={resource.name}>
                  {resource.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            startIcon={<AddIcon />}
            disabled={uploading} // Disable button while uploading
          >
            Upload New File
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx,.txt,.png,.jpg,.jpeg,.gif"
          />

          {uploading && <CircularProgress size={24} sx={{ marginLeft: 2 }} />}
        </Stack>

        <YesNoDialog
          dialogTitle="Send Email"
          confirmText="Send"
          dialogText={`Are you sure: you are ready to send this email to all ${selectedRegistrants.length} recipients?`}
          open={openConfirmation}
          onClose={handleConfirmation}
        />
      </DialogContent>
      <DialogActions>
        {emailSentConfirmation && (
          <>
            <Button
              sx={{ width: '200px' }}
              onClick={cancelEmail}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </>
        )}

        {!emailSentConfirmation && (
          <>
            <Button
              sx={{ width: '200px' }}
              onClick={cancelEmail}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={onSendClick}
              sx={{ width: '200px' }}
              variant="contained"
              color="primary"
            >
              Send
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SendEmailDialog;
