import { useMutation } from '@apollo/client';
import { getResourceForProgram_Name } from 'gql/programResources/getResourceForProgram';
import { AddResource, AddResourceVariables } from 'models/GeneratedModels';
import { addResource_Gql } from 'gql/programResources/addResource';
import { getProgramById_Name } from 'gql/program/getProgramByIdQuery';
import { reactapp_APIURL } from '../../../../../helpers/tenantHelpers';

import useAxios from 'hooks/useAxios';



export function useUploadResources() {
  const [saveResource, { loading: uploadingFiles }] = useMutation<AddResource, AddResourceVariables>(addResource_Gql, {
    refetchQueries: [getResourceForProgram_Name, getProgramById_Name],
  });

  const { get, put } = useAxios();

  const addResourcesToS3AndDB = async (programId: string, files: File[]) => {
    if (!files || files.length === 0) return { success: [], errors: [] };
  
    const resources = [];
    const errors = [];
  
    for (let file of files) {
      try {
        const url = `${reactapp_APIURL}/resource/upload?programId=${programId}&fileName=${file.name}&fileType=${file.type}`;
        const preSignedUrl = (await get(url)).data;
        const options = {
          // headers: {
          //  'Content-Type': file.type,
          //} 
        };
        
        await put(preSignedUrl, file, options, false);
  
        const response = await saveResource({
          variables: {
            resource: {
              name: file.name,
              programId,
            },
          },
        });
  
        resources.push(response.data?.addResource.id);
      } catch (error: any) {
        console.error('Error uploading file:', file.name, error);
        errors.push({ file: file.name, error: error.message || error });
      }
    }
  
    return { success: resources, errors };
  };  

  return { addResourcesToS3AndDB, uploadingFiles };
}
